import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loader"
export default class extends Controller {
  static targets = ['loader']
  connect() {
  }  
  show() {
    this.loaderTarget.classList.remove("opacity-0", "invisible");
    this.loaderTarget.classList.add("opacity-100", "visible");
  }
  hide() {
    this.loaderTarget.classList.remove("opacity-100", "visible");
    this.loaderTarget.classList.add("opacity-0", "invisible");
  }

}